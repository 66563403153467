/* @jsx jsx */
import * as React from "react";
import { jsx } from "theme-ui";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const textStyles = (align: string | string[]) => ({
  fontSize: [`3`, `4`],
  lineHeight: 1.5,
  "& > p": { m: 0, mb: `3`, textAlign: align, fontSize: [`3`, `4`] },
  "* a": { color: `magentaLighter`, "&:hover, &:focus": { color: `white` } },
  "& > ul": {
    pl: 0,
  },
});

const RichText: React.FC<{
  richTextNode: any;
  align: string | string[];
  sx: any;
}> = ({ richTextNode, align = [`center`, `left`], sx }) =>
  richTextNode?.raw && (
    <div sx={{ ...textStyles(align), ...sx }}>
      {renderRichText(richTextNode)}
    </div>
  );

export default RichText;
