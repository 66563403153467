/* @jsx jsx */
import * as React from "react";
import { jsx, Box, Flex, Text, Link } from "theme-ui";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const imageStyles = {
  // this is using important to overwrite the gatsby-plugin-image wrapper
  // that adds width/height to the container.
  width: [`48px !important`, null, `64px !important`, `72px !important`],
  height: [`48px !important`, null, `64px !important`, `72px !important`],
  borderRadius: 9999,
};

const Presenters: React.FC<{ presenters: any; textColor: string }> = ({
  presenters,
  textColor,
}) => {
  return presenters
    ? presenters?.map((presenter: object) => (
        <Flex sx={{ mb: `3` }}>
          <div className={"presenter"}>
            <Box
              sx={{
                ...imageStyles,
                backgroundColor: textColor,
                isolation: "isolate",
              }}
            >
              <GatsbyImage
                sx={{
                  objectFit: "cover",
                  alignSelf: "center",
                  filter: `grayscale(1)`,
                  mixBlendMode: "multiply",
                  ...imageStyles,
                }}
                loading="lazy"
                image={
                  presenter.headshot?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={presenter.name}
              />
            </Box>
          </div>
          <Box sx={{ paddingLeft: ["3", "3", "4"] }}>
            <Text sx={{ color: textColor, fontSize: "3", fontWeight: "bold" }}>
              {presenter.name}
            </Text>
            <Text sx={{ color: textColor }}>{presenter.title}</Text>
            {presenter.twitterHandle && (
              <Link
                href={`https://www.twitter.com/${presenter.twitterHandle}`}
                sx={{ color: textColor }}
                aria-label={`Visit ${presenter.name}'s Twitter profile`}
              >
                {`@${presenter.twitterHandle}`}
              </Link>
            )}
          </Box>
        </Flex>
      ))
    : null;
};

export default Presenters;

export const query = graphql`
  fragment Presenter on ContentfulSpeaker {
    name
    title
    twitterHandle
    headshot {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 72
            height: 72
            layout: FIXED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER, cropFocus: CENTER }
          )
          backgroundImg: gatsbyImageData(
            width: 240
            height: 240
            layout: FIXED
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            transformOptions: { fit: COVER, cropFocus: CENTER }
          )
        }
      }
    }
  }
`;
